import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default ({ articles }) => (
  <ul id="content_category">
    <li id="recent-posts-2" className="widget widget_recent_entries">
      <div className="widget_content">
        <h2 className="widget-title">Recent Posts</h2>
        <ul>
          {articles.map(article => (
            <li key={article.link}>
              <AniLink fade to={article.link}>
                {article.title}
              </AniLink>
            </li>
          ))}
        </ul>
      </div>
    </li>{" "}
    <li id="categories-2" className="widget widget_categories">
      <div className="widget_content">
        <h2 className="widget-title">Services</h2>
        <ul>
          <li className="cat-item">
            <AniLink fade to="/services">
              Online Branding
            </AniLink>
          </li>
          <li className="cat-item">
            <AniLink fade to="/services">
              Web Design
            </AniLink>
          </li>
          <li className="cat-item">
            <AniLink fade to="/services">
              Web Development
            </AniLink>
          </li>
          <li className="cat-item">
            <AniLink fade to="/services">
              Online Marketing
            </AniLink>
          </li>
        </ul>
      </div>
    </li>
    <li id="text-2" className="widget widget_text">
      <div className="widget_content">
        <h2 className="widget-title">Solutions</h2>
        <div className="textwidget">
          <ul>
            <li>
              <AniLink
                fade
                to="/solutions/digital-design"
                title="Digital Design"
              >
                Digital Design
              </AniLink>
            </li>
            <li>
              <AniLink
                fade
                to="/solutions/custom-applications"
                title="Custom Applications"
              >
                Custom Applications
              </AniLink>
            </li>
            <li>
              <AniLink fade to="/solutions/ecommerce" title="Ecommerce">
                Ecommerce
              </AniLink>
            </li>
            <li>
              <AniLink
                fade
                to="/solutions/managed-infrastructure"
                title="Managed Infrastructure"
              >
                Managed Infrastructure
              </AniLink>
            </li>

            <li>
              <AniLink
                fade
                to="/solutions/enterprise-security"
                title="Enterprise Security"
              >
                Enterprise Security
              </AniLink>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
)
