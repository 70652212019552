import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import logo from "../../assets/css/images/bloglogo.png"
import BlogSidebar from "../../components/blog-sidebar"
import { articles } from "../blog"

export default () => (
  <Layout isHome={false} pageId="blogpage" logo={logo}>
    <SEO title="Crafting a Design Persona" />
    <div id="content">
      <ul id="content_article">
        <li
          id="post-115"
          className="post-115 post type-post status-publish format-standard hentry category-blog"
        >
          <h1>Crafting a Design Persona</h1>
          <p className="postdetails">
            Posted on <span>June 02, 2015</span>
          </p>
          <div className="wrap_blog_text">
            <div className="contact-intro">
              Every product has a personality—whether it was deliberately
              designed to or not. Reddit is quirky, hyperactive, and sometimes
              sarcastic. Amazon is like a salesperson with an eidetic memory and
              amazing talent for statistics.
            </div>
            <p>
              Unlike a user persona, which characterizes your users’ goals,
              motivations, and desires, a design persona characterizes how your
              product should communicate and ultimately build rapport with your
              users. Both are articulated in terms of a fictional character, but
              they are used to solve different design problems. A user persona
              helps you understand your users’ existing relationship to your
              product, whereas a design persona helps you understand how your
              product can build a relationship with your users.
            </p>
            <p>
              In this article, I’ll show you how we came to think of our product
              as less of an “it” and more of a “someone” with an engaging, yet
              consistent, voice. I’ll also show how our design persona has
              become a continual source of product ideas.
            </p>
            <h3>The persona party</h3>
            <p>
              One of the most difficult aspects of creating a design persona
              (and arguably the most important) is to think of your product less
              like a collection of algorithms and more like a person. To achieve
              the right mindset, I asked our designers to imagine a fictitious
              “persona party” attended by all of our user personas, our key
              content creators, and, of course, our design persona.
            </p>
            <p>
              We then used scenarios to brainstorm our design persona’s
              potential reactions. For example: “Someone wanders up to you and
              asks, ‘Do you think I’ll need an umbrella today?’ How do you
              respond?”
            </p>
            <p>
              For each reaction, we debated how desirable it was and how true it
              was to our persona. For example, we realized that WU frequently
              displays graphs and tables of rich weather data, similar to the
              example response of “Don’t say a word, just point at a graph.” We
              decided that it would be much more approachable, friendly, and
              desirable to provide concise explanations of weather forecasts in
              addition to the detailed graphs and tables. However, several
              designers were quick to point out that WU shouldn’t be too
              friendly—for example, it would be off-putting and distracting to
              tell a joke when users are looking for the forecast.
            </p>{" "}
            <h3>Responses in context</h3>
            <p>
              We realized that people (and design personas) behave differently,
              and may assume a different identity, depending on who they are
              talking to and the context of the conversation. For example, your
              doctor may be dominant and unaffiliative while discussing medical
              treatments with you, but will become submissive and affiliative
              while discussing Thanksgiving dinner plans with their grandmother.
              We are never just one spot on a personality map; our design
              persona should act differently depending on the context, too.
            </p>
            <p>
              We decided that rather than picking a single spot on the
              personality map, we would draw out multiple points and context
              zones. For example, you can see that during “Severe Weather,” we
              want WU to sound and act like an authority. However, when we have
              a system failure and end up in the “Apology Zone,” we want to be
              conciliatory and apologetic.
            </p>
          </div>
        </li>
      </ul>

      <BlogSidebar articles={articles} />
    </div>
  </Layout>
)
