import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import logo from "../assets/css/images/bloglogo.png"
import BlogSidebar from "../components/blog-sidebar"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const articles = [
  {
    title: "Prototyping Your Workflow",
    date: "June 03, 2014",
    excerpt: `
    There’s a seductive danger present whenever you see someone else outline their way of working, however.
    It’s easy to take their process as a rigid, universal truth. The trouble is, you and your team aren’t
    like everyone else—you have different strengths and weaknesses.
    `,
    link: "/articles/prototyping-your-workflow",
  },
  {
    title: "UX for the Enterprise",
    date: "November 18, 2014",
    excerpt: `
    Imagine this scenario. You’re hired to design a product that has a guaranteed audience of 50,000 users,
    right out of the gate. Your clients have a dedicated support staff with a completely predictable technology stack.
    Best of all, your work directly improves the quality of your users’ lives.
    `,
    link: "/articles/ux-for-the-enterprise",
  },
  {
    title: "Crafting a Design Persona",
    date: "June 02, 2015",
    excerpt: `
    Every product has a personality—whether it was deliberately designed to
     or not. Reddit is quirky, hyperactive, and sometimes sarcastic. Amazon
      is like a salesperson with an eidetic memory and amazing talent for
      statistics.
    `,
    link: "/articles/crafting-a-design-persona",
  },
  {
    title: "The Language of Modular Design",
    date: "August 11, 2015",
    excerpt: `
    As many of us move away from designing pages toward designing systems, one concept keeps cropping up: modularity.
    We often hear about the benefits of a modular approach; modules are scalable, replaceable, reusable, easy to test,
    quick to put together—“They’re just like LEGO!”
    `,
    link: "/articles/the-language-of-modular-design",
  },
]

export default () => (
  <Layout isHome={false} pageId="blogpage" logo={logo}>
    <SEO title="Blog" />
    <div id="content">
      <ul id="content_article">
        {articles.map(article => (
          <li key={article.title} className="article-list-item">
            <AniLink
              fade
              to={article.link}
              title={article.title}
              rel="bookmark"
            >
              <h1>{article.title}</h1>
              <p className="postdetails">
                Posted on <span>{article.date}</span>
              </p>
              <div className="wrap_blog_text">
                <p>
                  {article.excerpt}
                  <br />
                </p>
              </div>
            </AniLink>
          </li>
        ))}
      </ul>

      <BlogSidebar articles={articles} />
    </div>
  </Layout>
)
